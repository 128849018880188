import axios from 'axios'

export default {
    get: () => axios.get('challenges'),
    getActives: () => axios.get('active-challenges'),
    show: (id) => axios.get('challenges/'+id+'/show'),
    store: (data) => axios.post('challenges/store', data),
    update: (data) => axios.post('challenges/update', data),
    delete: (id) => axios.post('challenges/delete', id),
    reactive: (data) => axios.post('challenges/reactive', data),
    deactive: (data) => axios.post('challenges/deactive', data),
    completeChallenge: (challenge_id) => axios.post('challenges/complete', {challenge_id: challenge_id}),
    addVideo: (data) => axios.post('challenges/add-video', data),
    deleteVideo: (data) => axios.post('challenges/remove-video', data),
    changeRate: (data) => axios.post('challenges/change-video-rate', data),
    updateAwards: (data) => axios.post('challenges/update-awards', data),
    updateRules: (data) => axios.post('challenges/update-rules', data),
    getQuestions: (challenge_id) => axios.get('challenges/'+challenge_id+'/simple-questions'),
    storeQuestion: (data) => axios.post('challenges/questions/store', data),
    updateQuestion: (data) => axios.post('challenges/questions/update', data),
    deleteQuestion: (id) => axios.post('challenges/questions/delete', id),
    
    getEntries: (challenge_id) => axios.get('challenges/'+challenge_id+'/entries'),
    deleteEntry: (id) => axios.post('challenges/entries/delete', id),
    paidEntry: (id) => axios.post('challenges/entries/paid', id),
    unpaidEntry: (id) => axios.post('challenges/entries/unpaid', id),
    getAvailables: (challenge_id) => axios.get('challenges/'+challenge_id+'/availables'),
    deleteAvailable : (id) => axios.post('challenges/availables/delete', id),
    importAvailables: (data) => axios.post('challenges/availables/import', data),
    addSupplier: (data) => axios.post('challenges/availables/add-supplier', data),

    saveAnswers: (data) => axios.post('challenges/questions/answers', data),
    uploadQuestion: (data) => axios.post('challenges/questions/upload', data),

    getProducts: (challenge_id) => axios.get('challenges/'+challenge_id+'/products'),
    deleteProduct: (id) => axios.post('challenges/products/delete', id),
    importProducts: (data) => axios.post('challenges/products/import', data),
    storeProduct: (data) => axios.post('challenges/products/store', data),
    updateProduct: (data) => axios.post('challenges/products/update', data),

    getSales: (challenge_id) => axios.get('challenges/'+challenge_id+'/sales'),
    getSalesFiltered : (challenge_id, filters) => axios.post('challenges/'+challenge_id+'/sales', filters),
    deleteSale: (id) => axios.post('challenges/sales/delete', id),

    getProgress: (challenge_id) => axios.post('challenges/get-progress', {challenge_id: challenge_id}),
}